import React, { useState } from "react";
import {
    HeroContainer,
    HeroContent,
    HeroH1,
    HeroH2,
    HeroBtnWrapper,
    BtnText
} from "./HeroElements";
import { Button, BtnGroup, Group } from "../ButtonElements";

import { FaRegImage, FaFortAwesome, FaPalette, FaExpand, FaMagic, FaGamepad, FaParachuteBox, FaGlobe, FaSun, FaBitcoin, FaCandyCane, FaBookDead, FaHatWizard, FaExternalLinkAlt } from "react-icons/fa";

import { BiBitcoin } from "react-icons/bi";

import Sol from "../../fonts/sol.svg";


window.addEventListener('DOMContentLoaded', () => {
    const element = document.getElementById('bottom');

    window.addEventListener('mousemove', (event) => {
        const mouseY = event.clientY;
        const height = mouseY; // calculate height based on mouse position
        element.style.height = `${height}vh`;
    });
});


const HeroSection = () => {
    // show about;    
    const [hoveredButton, setHoveredButton] = useState(null);

    const handleButtonHover = (buttonId) => {
        setHoveredButton(buttonId);
    };

    const handleMouseLeave = () => {
        setHoveredButton(null);
    };

    // show/hide groups;
    const [showGroups, setShowGroups] = useState(true);
    const [showArrow, setShowArrow] = useState(false);

    const handleGalleryClick = () => {
        setShowGroups(!showGroups);
        setShowArrow(!showArrow);
    };

    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (imageIndex) => {
        setSelectedImage(imageIndex);
    };

    const images = [];
    for (let i = 1; i <= 50; i++) {
        images.push(`/images/critters/${i}.png`);
    }

    const imgsuns = [];
    for (let i = 1; i <= 50; i++) {
        imgsuns.push(`/images/sunsets/${i}.png`);
    }




    return (
        <HeroContainer id="home" >
            <div id="suns" className="logo-flex">
                <div className="logo-top">
                    <img src="/logo-top.png" alt="suns-top" /></div>
                <div id="bottom" className="logo-bottom">
                    <img src="/logo-suns.png" alt="suns-bottom" /></div>
            </div>
            <div className="header">
                <HeroH1>suns studio</HeroH1>
                <HeroH2>A pixel art studio founded by <a href="https://twitter.com/rgb0x00">RGB</a> to gather all his creations and to build a long-term relationship with artists, collectors and enthusiasts, as a self-sustained space of constant art-driven production. </HeroH2></div>
            <HeroContent className={`content ${showGroups ? "" : "hidden"}`}>
                <Group className={`groups ${showGroups ? "" : "hidden"}`}><p>Collections</p>
                    <BtnGroup>
                        <HeroBtnWrapper>
                            <Button
                                to=''
                                id='gallery'
                                onClick={handleGalleryClick}
                                onMouseEnter={() => handleButtonHover("gallery")}
                                onMouseLeave={handleMouseLeave}
                            >{hoveredButton === "gallery" && (
                                <div className="about"><FaRegImage /><span>An overview of RGB creations: Solsunsets and Critters Cult generative collections, 1/1 artworks, limited editions, commissions and collaborations. </span></div>
                            )}  <BtnText className="text">{showArrow ? "" : "Gallery"}</BtnText>&nbsp;<FaPalette />
                            </Button>
                        </HeroBtnWrapper>
                        <div className={`gallery ${showGroups ? "hidden" : "show"}`}>
                            <Button
                                to=''
                                id='arrow'
                                onClick={handleGalleryClick}
                            >  <BtnText className="text">{showArrow ? "" : ""}</BtnText>
                            </Button>
                            <div className="gallery-flex">
                                <figure onClick={() => handleImageClick(1)}>
                                    <img src="/images/1.png" alt="1" />
                                    {selectedImage === 1 && (
                                        <figcaption className="cptn">"Solsunsets #1152", the OG generative landscape collection by RGB. Available at <a href="https://www.tensor.trade/trade/solsunsets" target="blank">Tensor</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(2)}>
                                    <img src="/images/2.png" alt="2" />
                                    {selectedImage === 2 && (
                                        <figcaption className="cptn">"Bwononoke Hime", a special edition collab for <a href="https://twitter.com/gengoya_club" target="blank">@geongoya_club</a>. Available at <a href="https://objkt.com/asset/KT1Rs1uhx5Fwp6Q4FBV8kXL9XPf3cPbfTwWP/36" target="blank">Objekt</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(3)}>
                                    <img src="/images/3.png" alt="3" />
                                    {selectedImage === 3 && (
                                        <figcaption className="cptn">"Tears of the Kingdom", a limited edition part of the Fantasy Series. Available at
                                            {` `}<a href="https://exchange.art/series/Fantasy%20Series
" target="blank">ExchangeArt</a><FaExternalLinkAlt /></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(4)}>
                                    <img src="/images/4.gif" alt="4" />
                                    {selectedImage === 4 && (
                                        <figcaption className="cptn">"Morag", part of the Sci-Fi Series. Available at <a href="https://exchange.art/single/3hYWkoXdtgh2ewdyjqfLTCRR7h2uuMWcCze1nmMYhmzh" target="blank">ExchangeArt</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(5)}>
                                    <img src="/images/5.png" alt="5" />
                                    {selectedImage === 5 && (
                                        <figcaption className="cptn">"Call of the Kings", a special edition collab for <a href="https://twitter.com/DeadKingSociety" target="blank">@DeadKingSociety</a>. Available at <a href="https://magiceden.io/marketplace/call_of_the_kings" target="blank">Magic Eden </a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(6)}>
                                    <img src="/images/6.png" alt="6" />
                                    {selectedImage === 6 && (
                                        <figcaption className="cptn">Critters Cult, the first profile picture collection by RGB. Available at <a href="https://www.tensor.trade/trade/critters_cult" target="blank">Tensor</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(7)}>
                                    <img src="/images/7.gif" alt="7" />
                                    {selectedImage === 7 && (
                                        <figcaption className="cptn">"Acheron (LV-426)", part of the Sci-Fi Series. Available at <a href="https://exchange.art/single/J7sUWghHmYaaq7KQeRfpSKDwygJEjHZrMx95AQ66dYN5" target="blank">ExchangeArt</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(8)}>
                                    <img src="/images/8.png" alt="8" />
                                    {selectedImage === 8 && (
                                        <figcaption className="cptn">"Katabasis", a special edition collab for <a href="https://twitter.com/HotHeadsNFT" target="blank">@HotHeadsNFT</a>. Available at <a href="https://exchange.art/editions/GVsRBNPKUBRDYeJ54SEhWHHC4FSBvyUfZB9z6ysBoG4d" target="blank">ExchangeArt</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(9)}>
                                    <img src="/images/9.png" alt="9" />
                                    {selectedImage === 9 && (
                                        <figcaption className="cptn">"The Wanderer", a special edition drop to celebrate Solsunsets' first anniversary<a href="https://exchange.art/editions/45QeXbJ3gL5WoAzUyVGXc7HZz5KoXD9K1RMnrJW9JVMQ" target="blank">ExchangeArt</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(10)}>
                                    <img src="/images/10.png" alt="10" />
                                    {selectedImage === 10 && (
                                        <figcaption className="cptn">"The Wall", part of the Fantasy Series. Available at
                                            {` `}<a href="https://exchange.art/series/Fantasy%20Series
" target="blank">ExchangeArt</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(11)}>
                                    <img src="/images/11.png" alt="11" />
                                    {selectedImage === 11 && (
                                        <figcaption className="cptn">"Cobalt Alien", a special edition collab for <a href="https://twitter.com/Zen0" target="blank">@Zen0</a>. Available at  <a href="https://exchange.art/editions/EwHmyU51kqckhqcCZUa691sXT3VKDRS31to1jEWUNttM" target="blank">ExchangeArt</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(12)}>
                                    <img src="/images/12.png" alt="12" />
                                    {selectedImage === 12 && (
                                        <figcaption className="cptn">"Kodama Forest", a limited edition part of the Fantasy Series. Available at
                                            {` `}<a href="https://exchange.art/series/Fantasy%20Series
" target="blank">ExchangeArt</a><FaExternalLinkAlt /></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(13)}>
                                    <img src="/images/13.png" alt="13" />
                                    {selectedImage === 13 && (
                                        <figcaption className="cptn">Unreleased collab piece for <a href="https://twitter.com/XeanusMonkeysN" target="blank">@XeanusMonkeysN</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(14)}>
                                    <img src="/images/14.png" alt="14" />
                                    {selectedImage === 14 && (
                                        <figcaption className="cptn">"The Zone", part of the Sci-Fi Series. Available at <a href="https://exchange.art/single/5DyGkRsHCQoJUHwTisUuFyE8CgWToXZe16C5GUCH5rd2" target="blank">ExchangeArt</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(15)}>
                                    <img src="/images/15.png" alt="15" />
                                    {selectedImage === 15 && (
                                        <figcaption className="cptn">"Smaug's Lair" , a limited edition part of the Fantasy Series. Available at
                                            <a href="https://exchange.art/series/Fantasy%20Series
" target="blank">ExchangeArt</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(16)}>
                                    <img src="/images/16.gif" alt="16" />
                                    {selectedImage === 16 && (
                                        <figcaption className="cptn">"Neo-Tokyo", part of the Sci-Fi Series. Available at <a href="https://exchange.art/single/ASWutcyXWN6hvouJd9afPucFS9dyiSJR61q27Q1bedxq" target="blank">ExchangeArt</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(17)}>
                                    <img src="/images/17.png" alt="17" />
                                    {selectedImage === 17 && (
                                        <figcaption className="cptn">1 of 10 unique pieces part of the <a href="https://www.twitter.com/The_URS_" target="blank">@The_URS_</a> drop "Ethan Frome". See all pieces at <a href="https://magiceden.io/marketplace/urs05?status=%5B%22all%22%5D&attributes=%7B%22Artist%22%3A%5B%22%7B%5C%22value%5C%22%3A%5C%22RGB%5C%22%7D%22%5D%7D" target="blank">Magic Eden</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(18)}>
                                    <img src="/images/18.png" alt="18" />
                                    {selectedImage === 18 && (
                                        <figcaption className="cptn">"The Neon", a legendary Critter. Available at <a href="https://www.tensor.trade/trade/critters_cult" target="blank">Tensor</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(19)}>
                                    <img src="/images/19.gif" alt="19" />
                                    {selectedImage === 19 && (
                                        <figcaption className="cptn">"Equator", part of the Sci-Fi Series. Available at  <a href="https://exchange.art/single/Hi6jiqrTcw89TP7pf6LgY8LdXYkUR6UHwuaKzx1bbqDq" target="blank">ExchangeArt</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(20)}>
                                    <img src="/images/20.png" alt="20" />
                                    {selectedImage === 20 && (
                                        <figcaption className="cptn">"Antarctica", part of the Sci-Fi Series. Available at  <a href="https://exchange.art/single/38icp5VPHkEqxMCNTLJcp7Af4js7zGxsLCxv8YnScZ4k" target="blank">ExchangeArt</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(21)}>
                                    <img src="/images/21.png" alt="21" />
                                    {selectedImage === 21 && (
                                        <figcaption className="cptn">"FFF x Critters Cult", a special edition collab for <a href="https://twitter.com/FamousFoxFed" target="blank">@FamousFoxFed</a>. Available at  <a href="https://exchange.art/editions/H7NKQr5Lno4rUKXHfJjq3JuZRX95RxFACGDwd7gSdNB9" target="blank">ExchangeArt</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(22)}>
                                    <img src="/images/22.png" alt="22" />
                                    {selectedImage === 22 && (
                                        <figcaption className="cptn">"The Fall of the Eradicator", a special edition collab for <a href="https://twitter.com/TheChimpions" target="blank">@TheChimpions</a>. Available at  <a href="https://exchange.art/editions/EwUqVUdJEaGL51P5ui6XjT6Xoe2r6d69W1iCXiVzkQuG" target="blank">ExchangeArt</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(23)}>
                                    <img src="/images/23.png" alt="23" />
                                    {selectedImage === 23 && (
                                        <figcaption className="cptn">"Splendor Solis", part of the "Forked Suns". Available at <a href="https://exchange.art/series/The%20Forked%20Suns" target="blank">ExchangeArt</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(24)}>
                                    <img src="/images/24.png" alt="24" />
                                    {selectedImage === 24 && (
                                        <figcaption className="cptn">"Hot Heads x RGB", a special collab piece for <a href="https://twitter.com/HotHeadsNFT" target="blank">@HotHeadsNFT</a>. Available at <a href="https://exchange.art/single/2Gr2wxYFJLp2F54svtcm4L6zSNre1yVUmPXKZu7tdGqS" target="blank">ExchangeArt</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(25)}>
                                    <img src="/images/25.png" alt="25" />
                                    {selectedImage === 25 && (
                                        <figcaption className="cptn">"The Cosmic Shuffler", a special piece part of the <a href="https://library.critterscult.com/" target="blank">Library</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(26)}>
                                    <img src="/images/26.png" alt="26" />
                                    {selectedImage === 26 && (
                                        <figcaption className="cptn">"Lake of Serenity", a special collab piece for <a href="https://twitter.com/VibeKnights" target="blank">@VibeKnights</a>.</figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(27)}>
                                    <img src="/images/27.png" alt="27" />
                                    {selectedImage === 27 && (
                                        <figcaption className="cptn">"Solsunsets #3323", the OG generative landscape collection by RGB. Available at <a href="https://www.tensor.trade/trade/solsunsets" target="blank">Tensor</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(28)}>
                                    <img src="/images/28.png" alt="28" />
                                    {selectedImage === 28 && (
                                        <figcaption className="cptn">"The Old Chapel", a special piece part of the Critters Cult's promotional material.</figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(29)}>
                                    <img src="/images/29.png" alt="29" />
                                    {selectedImage === 29 && (
                                        <figcaption className="cptn">"The Cultists", a special piece part of the Critters Cult's promotional material.</figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(30)}>
                                    <img src="/images/30.png" alt="30" />
                                    {selectedImage === 30 && (
                                        <figcaption className="cptn">A "Nexus Solis", part of the "Forked Suns". Available at <a href="https://exchange.art/series/The%20Forked%20Suns" target="blank">ExchangeArt</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(31)}>
                                    <img src="/images/31.png" alt="31" />
                                    {selectedImage === 31 && (
                                        <figcaption className="cptn">1 of 10 unique pieces part of the <a href="https://www.twitter.com/The_URS_" target="blank">@The_URS_</a> drop "A Tale of Two Cities". See all pieces at <a href="https://magiceden.io/marketplace/urs07?status=%5B%22all%22%5D&attributes=%7B%22Artist%22%3A%5B%22%7B%5C%22value%5C%22%3A%5C%22RGB0x00%5C%22%7D%22%5D%7D" target="blank">Magic Eden</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(32)}>
                                    <img src="/images/32.png" alt="32" />
                                    {selectedImage === 32 && (
                                        <figcaption className="cptn">A "Children of Moss" member, from the Critters Cult collection. Available at <a href="https://www.tensor.trade/trade/critters_cult" target="blank">Tensor</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(33)}>
                                    <img src="/images/33.png" alt="33" />
                                    {selectedImage === 33 && (
                                        <figcaption className="cptn">"Mr. Clay", a special collab piece for <a href="https://twitter.com/Claynosaurz" target="blank">@Claynosaurz</a>. Available at <a href="https://exchange.art/series/Critters%20Cult:%20Spin-offs
                                        " target="blank">ExchangeArt</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(34)}>
                                    <img src="/images/34.png" alt="34" />
                                    {selectedImage === 34 && (
                                        <figcaption className="cptn">"New York", part of the Sci-Fi Series. Available at <a href="https://exchange.art/single/4j124K2iLMSGG4VcFpSH663FySrVYQGSpquapbTX7avx" target="blank">ExchangeArt</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(35)}>
                                    <img src="/images/35.gif" alt="35" />
                                    {selectedImage === 35 && (
                                        <figcaption className="cptn">"Jump Program", part of the Sci-Fi Series. Available at <a href="https://exchange.art/single/3ESTcXeNLYWzw2dzauWsbCLA4AFYu71SwRn5QoKkgeWV" target="blank">ExchangeArt</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(36)}>
                                    <img src="/images/36.png" alt="36" />
                                    {selectedImage === 36 && (
                                        <figcaption className="cptn">"Johanesburg", part of the Sci-Fi Series. Available at <a href="https://exchange.art/single/2974DzA46rLgih6KiwJfThBx4mdWXMmaRbCuPKdDLr9P" target="blank">ExchangeArt</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(37)}>
                                    <img src="/images/37.gif" alt="37" />
                                    {selectedImage === 37 && (
                                        <figcaption className="cptn">"Tatooine", part of the Sci-Fi Series. Available at <a href="https://exchange.art/single/KZMYJYxSNaR4PxfQofuJmoSLNKjiY5uHkfyVhJhqiQB" target="blank">ExchangeArt</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(38)}>
                                    <img src="/images/38.gif" alt="38" />
                                    {selectedImage === 38 && (
                                        <figcaption className="cptn">"The Pyromaniac Koko", a special collab piece for <a href="https://twitter.com/marculinopng" target="blank">@marculinopng</a>. Available at  <a href="https://exchange.art/single/BXCXt5z7vQsUbqKkjCGD8gbd8zRCuPiGBLuPZ7bF2qQh" target="blank">ExchangeArt</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(39)}>
                                    <img src="/images/39.png" alt="39" />
                                    {selectedImage === 39 && (
                                        <figcaption className="cptn">"Puffbird Night", a special piece part of the <a href="https://loopchronicles.app/" target="blank">Loop Chronicles</a> game.</figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(40)}>
                                    <img src="/images/40.png" alt="40" />
                                    {selectedImage === 40 && (
                                        <figcaption className="cptn">"Lost in the Void", a special piece part of the Critters Cult's promotional material.</figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(41)}>
                                    <img src="/images/41.png" alt="41" />
                                    {selectedImage === 41 && (
                                        <figcaption className="cptn">"Fulminis Solis", part of the "Forked Suns". Available at <a href="https://exchange.art/series/The%20Forked%20Suns" target="blank">ExchangeArt</a></figcaption>
                                    )}
                                </figure>
                                <figure onClick={() => handleImageClick(42)}>
                                    <img src="/images/42.png" alt="42" />
                                    {selectedImage === 42 && (
                                        <figcaption className="cptn">"Double C Diner", a special piece part of the <a href="https://loopchronicles.app/" target="blank">Loop Chronicles</a> game.</figcaption>
                                    )}
                                </figure>
                            </div>

                            {selectedImage !== null && (
                                <div className="image-overlay" onClick={() => setSelectedImage(null)}>
                                    {selectedImage !== null && (
                                        <img
                                            src={`/images/${selectedImage}.${(selectedImage === 4 || selectedImage === 7 || selectedImage === 16 || selectedImage === 19 || selectedImage === 38 || selectedImage === 35 || selectedImage === 37) ? 'gif' : 'png'}`}
                                            className={(selectedImage === 4 || selectedImage === 7 || selectedImage === 16 || selectedImage === 19 || selectedImage === 38 || selectedImage === 35 || selectedImage === 37) ? 'x4' : ''}
                                            alt={`${selectedImage}`}
                                        />
                                    )}
                                </div>
                            )}


                        </div>
                        <HeroBtnWrapper>
                            <Button
                                to=''
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open('https://solsunsets.com', '_blank');
                                }}
                                onMouseEnter={() => handleButtonHover("sunsets")}
                                onMouseLeave={handleMouseLeave}
                            >{hoveredButton === "sunsets" && (
                                <div className="about"><FaExternalLinkAlt /><img className="chains" src={Sol} /> <span>Solsunsets is a generative art collection of 4,444 landscapes inspired by the aesthetics of obsolete technologies and the visual culture from the 80s.</span><img className="saga" src="/sunsets-b.png" alt="sunsets" />

                                    <div className="image-carousel-container">
                                        <div className="image-carousel">
                                            {imgsuns.map((imgsuns, index) => (
                                                <img key={index} src={imgsuns} alt={`Image ${index}`} />
                                            ))}
                                        </div>
                                    </div>





                                </div>
                            )}<BtnText className="text">Solsunsets </BtnText>&nbsp;<FaGlobe />
                            </Button>
                        </HeroBtnWrapper>
                        <HeroBtnWrapper>
                            <Button
                                to=''
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open('https://critterscult.com', '_blank');
                                }}
                                onMouseEnter={() => handleButtonHover("cc")}
                                onMouseLeave={handleMouseLeave}
                            >{hoveredButton === "cc" && (
                                <div className="about"><FaExternalLinkAlt /><img className="chains" src={Sol} /> <span>Critters cult is a narrative-driven sci-fi project and generative collection of 5,555 creatures living in a bizarre world.
                                </span>                      <img className="critters" src="/critters_small.png" alt="critters" />

                                    <div className="image-carousel-container">
                                        <div className="image-carousel">
                                            {images.map((image, index) => (
                                                <img key={index} src={image} alt={`Image ${index}`} />
                                            ))}
                                        </div>
                                    </div>


                                </div>
                            )}<BtnText className="text">Critters Cult </BtnText>&nbsp;<FaGlobe />
                            </Button>
                        </HeroBtnWrapper>

                        <HeroBtnWrapper>
                            <Button
                                to=''
                                id='soon'
                            onClick={(e) => {
                                    e.preventDefault();
                                    window.open('https://sunssaga.com', '_blank');
                                }}
                                onMouseEnter={() => handleButtonHover("saga")}
                                onMouseLeave={handleMouseLeave}
                            >{hoveredButton === "saga" && (
                                <div className="about"><FaExternalLinkAlt />  <img className="chains" src={Sol} /> <br />An epic landscape generative collection. Details TBA Q3 2023
                                    <img className="saga" src="/saga.png" alt="saga" /></div>
                            )}<BtnText className="text">Suns: Saga</BtnText>&nbsp;<FaSun />
                            </Button>
                        </HeroBtnWrapper>
                        <HeroBtnWrapper>
                            <Button
                                to=''
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open('https://nodescapes.com', '_blank');
                                }}
                                onMouseEnter={() => handleButtonHover("ord")}
                                onMouseLeave={handleMouseLeave}
                            >{hoveredButton === "ord" && (
                                <div className="about"><FaExternalLinkAlt /> <BiBitcoin /><br />A landscape collection of 111 digital artifacts inscribed on block 78 Sats. Details TBA Q3 2023
                                    <img className="saga" src="/frame.png" alt="ord" />

                                </div>
                            )}<BtnText className="text">Nodescapes</BtnText>&nbsp;<FaRegImage />
                            </Button>
                        </HeroBtnWrapper>

                    </BtnGroup>
                </Group>

                <Group className={`groups ${showGroups ? "" : "hidden"}`}><p>Worldbuilding</p>
                    <BtnGroup>
                        <HeroBtnWrapper>
                            <Button
                                to=''
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open('https://library.critterscult.com', '_blank');
                                }}
                                onMouseEnter={() => handleButtonHover("lib")}
                                onMouseLeave={handleMouseLeave}
                            >{hoveredButton === "lib" && (
                                <div className="about"><FaExternalLinkAlt /> <span>The Library is a comprehensive repository containing all the canonical information about the universe of Critters Cult and Suns:Saga, as well as the mythological origins of their their encompassing reality, the “Log”. Inside the library, you will also find all theories around the Realms, the Forks, the Forked Suns, the Relics, the fascinating backstories of all 44 legendary Critters, among many other unique elements of this universe and intriguing topics. Come visit the Library and indulge in this fascinating experience.
                                </span>
                                <img className="saga" src="/library.png" alt="library" />

                                </div>
                            )}<BtnText className="text">The Library </BtnText>&nbsp;<FaFortAwesome />
                            </Button>
                        </HeroBtnWrapper>
                        <HeroBtnWrapper>
                            <Button
                                to=''
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open('https://loopchronicles.app', '_blank');
                                }}
                                onMouseEnter={() => handleButtonHover("loop")}
                                onMouseLeave={handleMouseLeave}
                            >{hoveredButton === "loop" && (
                                <div className="about"><FaExternalLinkAlt /> <span>The Loop Chronicles is an interactive story game that takes place in an eerie small town, where you will immerse yourself to find out what is behind the strange events you will come across. It is a decision-making swipe game set in the Atom Realm, in which you will need to keep the balance of different aspects of your life to stay awake as long as possible and have a glimpse of the mysterious world of Critters Cult.

                                </span>
                                
                                <img className="saga" src="/loop.png" alt="loop" />

                                </div>
                            )}<BtnText className="text">Loop Chronicles </BtnText>&nbsp;<FaGamepad />
                            </Button>
                        </HeroBtnWrapper>
                        <HeroBtnWrapper>
                            <Button
                                to=''
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open('https://book.critterscult.com', '_blank');
                                }}
                                onMouseEnter={() => handleButtonHover("book")}
                                onMouseLeave={handleMouseLeave}
                            >{hoveredButton === "book" && (
                                <div className="about"><FaExternalLinkAlt /> <span>The Critters Book is a collaborative compendium and an open platform where collectors can contribute with stories about their own Critters.                      </span>
                                
                                <img className="saga" src="/book.png" alt="book" width="140" />

                                </div>
                            )}<BtnText className="text">Critters Book </BtnText>&nbsp;<FaBookDead />
                            </Button>
                        </HeroBtnWrapper>
                        <HeroBtnWrapper>
                            <Button
                                to=''
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open('https://sunsstudio.com/artmap_4.0.pdf', '_blank');
                                }}
                                onMouseEnter={() => handleButtonHover("art")}
                                onMouseLeave={handleMouseLeave}
                            >{hoveredButton === "art" && (
                                <div className="about"><FaExternalLinkAlt /> <span>The Suns Studio Artmap 4.0 is the latest update of our goals, objectives and key steps of this epic journey, presenting our ecosystem, manifesto, core values, and projects in detail.
                                </span></div>
                            )}<BtnText className="text">The ArtMap 4.0 </BtnText>&nbsp;<FaHatWizard />
                            </Button>
                        </HeroBtnWrapper>
                    </BtnGroup>
                </Group>
                <Group className={`groups ${showGroups ? "" : "hidden"}`}><p>Market</p>
                    <BtnGroup>
                        <HeroBtnWrapper>
                            <Button
                                to=''
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open('https://exchange.art/suns/series', '_blank');
                                }}
                                onMouseEnter={() => handleButtonHover("ea")}
                                onMouseLeave={handleMouseLeave}
                            >{hoveredButton === "ea" && (
                                <div className="about"><FaExternalLinkAlt /> <span>The Suns Studio special collections, 1/1s and edition drops, such as the Critters Cult: Lodge, Critters Cult: Spinoffs, The Forked Suns, Sci-Fi Series, Fantasy Series and all our collabs can be visited in our main page on Exchange Art.

                                </span>
                                    <img src="/fantasy-logo.png" alt="fantasy" />
                                    <img src="/sci-fi-logo.png" alt="sci-fi" />
                                    <img src="/forked-logo.png" alt="forked" />
                                    <img src="/collabs-logo.png" alt="collabs" />
                                    <img src="/lodge.png" alt="lodge" />



                                </div>
                            )}<BtnText className="text">Exchange Art</BtnText>&nbsp;<FaExpand />
                            </Button>
                        </HeroBtnWrapper>
                        <HeroBtnWrapper>
                            <Button
                                to=''
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open('https://magiceden.io/creators/suns', '_blank');
                                }}
                                onMouseEnter={() => handleButtonHover("me")}
                                onMouseLeave={handleMouseLeave}
                            >{hoveredButton === "me" && (
                                <div className="about"><FaExternalLinkAlt /> <span>The Suns Studio main generative collections, consisting on Critters Cult and Solsunsets, can be found here in our main page in the marketplace Magic Eden, where you can check our pieces available for purchase.
                                </span>
                                    <img className="critters" src="/critters_small.png" alt="critters" />
                                    <img className="saga" src="/sunsets-b.png" alt="sunsets" />
                                </div>
                            )}<BtnText className="text">MagicEden</BtnText>&nbsp;<FaMagic />
                            </Button>
                        </HeroBtnWrapper>
                    </BtnGroup></Group>
                <Group className={`groups ${showGroups ? "" : "hidden"}`}><p>Pixels</p>
                    <BtnGroup>
                        <HeroBtnWrapper>
                            <Button
                                to=''
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open('https://claim.sunsstudio.com', '_blank');
                                }}
                                onMouseEnter={() => handleButtonHover("claim")}
                                onMouseLeave={handleMouseLeave}
                            >{hoveredButton === "claim" && (
                                <div className="about"><FaExternalLinkAlt /><span>Suns Studio art collectors earn the token $Pixels, created with the purpose of benefiting our holders to purchase our exclusive creations. Holders of Critters Cult and Solsunsets earn 1 $Pixel a day per piece held in their wallet, added up to their balance every Monday and claimable once a week.

                                </span></div>
                            )}<BtnText className="text">Pixels Claim </BtnText>&nbsp;<FaParachuteBox />
                            </Button>
                        </HeroBtnWrapper>
                        <HeroBtnWrapper>
                            <Button
                                to=''
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open('https://drops.sunsstudio.com/', '_blank');
                                }}
                                onMouseEnter={() => handleButtonHover("drops")}
                                onMouseLeave={handleMouseLeave}
                            >{hoveredButton === "drops" && (
                                <div className="about"><FaExternalLinkAlt /><span>The Suns Drop House is the place where Suns Studio drops and commissions are auctioned and raffled in our native token, $Pixels.
                                </span></div>
                            )}<BtnText className="text">Art Drops</BtnText>&nbsp;<FaCandyCane />
                            </Button>
                        </HeroBtnWrapper>
                    </BtnGroup>
                </Group>

            </HeroContent>
        </HeroContainer >
    );
};
export default HeroSection;